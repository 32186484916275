@import "custom";
@import "bootstrap";
@import "fonts";
@import "form";
@import "btn";
@import "footer";
@import "landing";


html,
body {
  width: 100%;
  height: 100%;
}
