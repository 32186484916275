// font faces
@font-face {
  font-weight: normal;
  font-family: "ProximaNovaRegular";
  font-style: normal;
  src: url("../fonts/proxima-nova/proxima-nova-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima-nova/proxima-nova-regular-webfont.woff") format("woff"),
    url("../fonts/proxima-nova/proxima-nova-regular-webfont.ttf") format("truetype"),
    url("../fonts/proxima-nova/proxima-nova-regular-webfont.svg#ProximaNovaRegular") format("svg");
}

@font-face {
  font-weight: normal;
  font-family: "ProximaNovaSemibold";
  font-style: normal;
  src: url("../fonts/proxima-nova/proxima-nova-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima-nova/proxima-nova-semibold-webfont.woff") format("woff"),
    url("../fonts/proxima-nova/proxima-nova-semibold-webfont.ttf") format("truetype"),
    url("../fonts/proxima-nova/proxima-nova-semibold-webfont.svg#ProximaNovaSemibold") format("svg");
}

@font-face {
  font-weight: normal;
  font-family: "ProximaNovaBold";
  font-style: normal;
  src: url("../fonts/proxima-nova/proxima-nova-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proxima-nova/proxima-nova-bold-webfont.woff") format("woff"),
    url("../fonts/proxima-nova/proxima-nova-bold-webfont.ttf") format("truetype"),
    url("../fonts/proxima-nova/proxima-nova-bold-webfont.svg#ProximaNovaLight") format("svg");
}

// fonts variables
$proxima-nova-regular: "ProximaNovaRegular", sans-serif !default;
$proxima-nova-semi-bold: "ProximaNovaSemibold", sans-serif !default;
$proxima-nova-bold: "ProximaNovaBold", sans-serif !default;

// fonts utils class
.f-regular {
  font-family: $proxima-nova-regular !important;
}

.f-semi-bold {
  font-family: $proxima-nova-semi-bold !important;
}

.f-bold {
  font-family: $proxima-nova-bold !important;
}
