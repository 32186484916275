// form label
$form-label-font_size: 14px !default;
$form-label-line-height: 1.25 !default;

// form control
$form-control-font-size: 10px !default;
$form-control-lg-font-size: 14px !default;
$form-control-line-height: 2.5 !default;
$form-control-lg-line-height: $form-control-line-height !default;

$form-control-height: 40px !default;
$form-control-lg-height: 50px !default;
$form-control-color: #979797 !default;

$form-field-error-font-size: 14px !default;

.landing-form__title {
  margin-bottom: 1.25rem;
  font-family: $proxima-nova-bold;
}

.landing-form .form-label,
.landing-form .custom-control-label {
  font-size: $form-label-font_size;
  font-family: $proxima-nova-semi-bold;
}

.landing-form .form-label {
  margin-bottom: 0;
  line-height: $form-label-line-height;

  & label {
    margin-bottom: 0;
  }
}

.landing-form .form-control {
  height: $form-control-height;
  font-family: $proxima-nova-semi-bold;

  &::placeholder {
    color: $form-control-color;
    font-size: $form-control-font-size;
    line-height: $form-control-line-height;
  }

  &:focus {
    // placeholder (when focus)
    &::placeholder {
      color: transparent;
    }
  }
}


.landing-form textarea.form-control {
  height: inherit;
}

.landing-form .has-field-error {
  & .form-control {
    @extend .is-invalid
  }
}

// shrink margin bottom in form row when errors (maybe make it generic)
.landing-form .form-row .form-group.has-field-error {
  margin-bottom: 0;
}

.landing-form select {
  font-size: $form-control-font-size;
  line-height: $form-control-line-height;
  appearance: none;
  background-image: url("../img/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  background-size: 6px;
}

@include media-breakpoint-up(lg) {
  .landing-form {
    max-width: 550px;
  }

  .landing-form__title {
    margin-bottom: 2.5rem;
  }

  .landing-form .form-control {
    height: $form-control-lg-height;

    &::placeholder {
      font-size: $form-control-lg-font-size;
      line-height: $form-control-lg-line-height;
    }
  }

  .landing-form select {
    font-size: $form-control-lg-font-size;
    background-size: 0.5rem;
  }
}

/***
* Bootstrap-dualbox slider css
***/
select[multiple]::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	border-radius: 10px;
	background-color: #F5F5F5;
}

select[multiple]::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

select[multiple]::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	//background-color: rgba(234, 90, 1, 0.7);
	background-color: rgba(149, 193, 29, 0.66);
}
