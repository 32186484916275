$landing-bg: #95c11d !default;

/* Root */
.landing {
  font-family: $proxima-nova-regular;
  background-color: #95c11d;
}

/* Inner */
.landing__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  background-color: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

@include media-breakpoint-up(md) {
  .landing__inner {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

/* Hero */
.landing__hero {
  width: 100%;
  height: 300px;
  background: white url("../img/illustration.png") no-repeat center;
  background-size: contain;
}

@include media-breakpoint-up(md) {
  .landing__hero {
    height: 400px;
  }
}

@include media-breakpoint-up(lg) {
  .landing__hero {
    height: 500px;
  }
}

