/* Landing Footer */
.landing__footer {
  font-size: 10px;

  & a {
    color: $black;
    //letter-spacing: 0.025em;

    &:hover {
      color: $primary;
    }
  }
}

@include media-breakpoint-up(sm) {
  .landing__footer {
    font-size: 12px;
  }

  .landing__footer a {
    letter-spacing: 0.025em;
  }
}

@include media-breakpoint-up(xl) {
  .landing__footer {
    font-size: 1rem;
  }
  .landing__footer .copyright {
    font-size: 12px;
  }
}
